<template>
  <div
    :style="[
      { 'min-width': type == 'mb' ? 'unset' : '1030px' },
      { height: type == 'mb' ? '16vw' : '60px' },
    ]"
    class="m"
  >
    <!-- 移动 -->
    <div v-if="type == 'mb'" class="box2">
      <!-- 内容 -->

      <!-- 第一步 -->
      <div v-if="val == 1" class="neiron">
        <div class="item1">
          <div class="num">1</div>
          <p style="color: #12334c">Booking</p>
        </div>
        <div>
          <div class="xian1"></div>
          <div style="height: 5.3333vw"></div>
        </div>
        <div class="item2">
          <div style="background-color: #b2bac1" class="num">2</div>
          <p style="color: #b2bac1">Enter Info</p>
        </div>
        <div>
          <div class="xian2"></div>
          <div style="height: 5.3333vw"></div>
        </div>
        <div class="item3">
          <div style="background-color: #b2bac1" class="num">3</div>
          <p style="color: #b2bac1">Pay</p>
        </div>
      </div>
      <!-- 第二步 -->
      <div v-else-if="val == 2" class="neiron">
        <div class="item1">
          <div style="background-color: #426784" class="num">
            <SvgIcon iconClass="succeed"></SvgIcon>
          </div>
          <p style="color: #426784">Booking</p>
        </div>
        <div>
          <div style="background-color: #426784" class="xian1"></div>
          <div style="height: 5.3333vw"></div>
        </div>
        <div class="item2">
          <div class="num">2</div>
          <p style="color: #12334c">Enter Info</p>
        </div>
        <div>
          <div class="xian2"></div>
          <div style="height: 5.3333vw"></div>
        </div>
        <div class="item3">
          <div style="background-color: #b2bac1" class="num">3</div>
          <p style="color: #b2bac1">Pay</p>
        </div>
      </div>
      <!-- 第三步 -->
      <div v-else-if="val == 3" class="neiron">
        <div class="item1">
          <div style="background-color: #426784" class="num">
            <SvgIcon iconClass="succeed"></SvgIcon>
          </div>
          <p style="color: #426784">Booking</p>
        </div>
        <div>
          <div style="background-color: #426784" class="xian1"></div>
          <div style="height: 5.3333vw"></div>
        </div>
        <div class="item2">
          <div style="background-color: #426784" class="num">
            <SvgIcon iconClass="succeed"></SvgIcon>
          </div>
          <p style="color: #426784">Enter Info</p>
        </div>
        <div>
          <div style="background-color: #426784" class="xian2"></div>
          <div style="height: 5.3333vw"></div>
        </div>
        <div class="item3">
          <div style="background-color: #12334c" class="num">3</div>
          <p style="color: #12334c">Pay</p>
        </div>
      </div>
    </div>
    <!-- pc -->
    <div v-else class="box">
      <!-- 内容 -->

      <!-- 第一步 -->
      <div v-if="val == 1" class="neiron">
        <div class="item1">
          <div class="num">1</div>
          <p>Booking</p>
        </div>
        <div class="xian1"></div>
        <div class="item2">
          <div style="background-color: #b2bac1" class="num">2</div>
          <p style="color: #b2bac1">Enter Info</p>
        </div>
        <div class="xian2"></div>
        <div class="item3">
          <div style="background-color: #b2bac1" class="num">3</div>
          <p style="color: #b2bac1">Pay</p>
        </div>
      </div>
      <!-- 第二步 -->
      <div v-else-if="val == 2" class="neiron">
        <div class="item1">
          <div style="background-color: #426784" class="num">
            <SvgIcon iconClass="succeed" style="font-size: 14px"></SvgIcon>
          </div>
          <p style="color: #426784">Booking</p>
        </div>
        <div style="background-color: #426784" class="xian1"></div>
        <div class="item2">
          <div class="num">2</div>
          <p style="color: #12334c">Enter Info</p>
        </div>
        <div class="xian2"></div>
        <div class="item3">
          <div style="background-color: #b2bac1" class="num">3</div>
          <p style="color: #b2bac1">Pay</p>
        </div>
      </div>
      <!-- 第三步 -->
      <div v-else-if="val == 3" class="neiron">
        <div class="item1">
          <div style="background-color: #426784" class="num">
            <SvgIcon iconClass="succeed" style="font-size: 14px"></SvgIcon>
          </div>
          <p style="color: #426784">Booking</p>
        </div>
        <div style="background-color: #426784" class="xian1"></div>
        <div class="item2">
          <div style="background-color: #426784" class="num">
            <SvgIcon iconClass="succeed" style="font-size: 14px"></SvgIcon>
          </div>
          <p style="color: #426784">Enter Info</p>
        </div>
        <div style="background-color: #426784" class="xian2"></div>
        <div class="item3">
          <div style="background-color: #12334c" class="num">3</div>
          <p style="color: #12334c">Pay</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon.vue";
export default {
  props: {
    val: Number,
    type: String,
  },
  components: {
    SvgIcon,
  },
};
</script>

<style lang="less" scoped>
.m {
  width: 100%;
  min-width: 1030px;
  height: 60px;
  background-color: #fff;

  // pc
  .box {
    margin: 0 auto;
    width: 1030px;
    padding: 0 15px;
    height: 100%;
    .neiron {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: space-around;

      .item1,
      .item2,
      .item3 {
        display: flex;
        align-items: center;
        font-weight: bold;
        .num {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          background-color: #12334c;
          color: #fff;
          text-align: center;
          line-height: 26px;
          margin-right: 10px;
        }
      }

      .xian1,
      .xian2 {
        width: 200px;
        height: 2px;
        background-color: #b2bac1;
      }
    }
  }

  // mb
  .box2 {
    margin: 0 auto;
    padding: 0 15px;
    height: 100%;
    .neiron {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: space-evenly;

      .item1,
      .item2,
      .item3 {
        display: flex;
        align-items: center;
        font-weight: bold;
        flex-direction: column;
        font-size: 3.2vw;
        .num {
          width: 6.9333vw;
          height: 6.9333vw;
          border-radius: 50%;
          background-color: #12334c;
          color: #fff;
          text-align: center;
          line-height: 6.9333vw;
          margin-bottom: 6px;
        }
      }

      .xian1,
      .xian2 {
        width: 16.8vw;
        height: 2px;
        background-color: #b2bac1;
      }
    }
  }
}
</style>